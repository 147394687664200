import opendeliveryService from '@/services/opendelivery.service';

export const opendelivery = {
  namespaced: true,
  state: {
    opendelivery: {
      links: {},
      meta: {},
      results: []
    },
  },
  mutations: {
    fill_opendelivery(state, opendelivery) {
      state.opendelivery = opendelivery;
    }
  },
  actions: {
    async list({ commit }, queryString) {
      const response = await opendeliveryService.list(queryString);
      return response.code === 200 ? commit('fill_opendelivery', response.data) : null;
    }
  },
  getters: {
    opendelivery: (state) => state.opendelivery
  }
};
