import { mainAxios } from '@/plugins/axios';

class companyService {
  async list(columns = 'cod_emp,fantasia_emp') {
    return await mainAxios.get(`/empresas?columns=${columns}`);
  }
  async companies(query = '/empresas?page=1') {
    return await mainAxios.get(query);
  }
  async listAll(columns = 'cod_emp,fantasia_emp', limit = '', search= '', scope='') {
    if (limit) {
      limit = '&limit=' + limit;
    }

    if (scope) {
      const response = await mainAxios.get(
        `/empresas?columns=${columns}&scope=${scope}&paginate=false&search=${search}` + limit
      );
      return response.data;
    }

    const response = await mainAxios.get(
      `/empresas?columns=${columns}&scope=${scope}&paginate=false&search=${search}` + limit
    );
    return response.data;
  }
  async totalData() {
    const response = await mainAxios.get(`/totalizadores/home`);
    return response.data;
  }
}

export default new companyService();
