import { mainAxios } from '@/plugins/axios';

class catalogService {
  async list(url = '/catologo?page=1', perPage = 15, limit = 300, paginate = false, search) {
    const response = await mainAxios.get(
      `${url}&per_page=${perPage}&limit=${limit}&paginate=${paginate}&search=${search}`
    );
    return response.data;
  }
  async getCatalogs() {
    const response = await mainAxios.get(`/catalogo?order=desc&paginate=false`);
    return response.data;
  }
  async getCatalogById(id) {
    const response = await mainAxios.get(`/catalogo/${id}`);
    return response.data;
  }
  async clone(payload) {
    const response = await mainAxios.post(`/catalogo/empresa/clonar`, payload);
    return response.data;
  }
  async createCatalogCompany(payload) {
    const response = await mainAxios.post(`/catalogo/empresa`, payload);
    return response.data;
  }
  async deleteCatalogCompany(id) {
    const response = await mainAxios.delete(`/catalogo/empresa/${id}`);
    return response.data;
  }
  async createCatalog(payload) {
    const response = await mainAxios.post(`/catalogo`, payload);
    return response.data;
  }
  async deleteCatalog(id) {
    const response = await mainAxios.delete(`/catalogo/${id}`);
    return response.data;
  }
  async updateCatalog(id, payload) {
    const response = await mainAxios.put(`/catalogo/${id}`, payload);
    return response.data;
  }
  async getSubCatalogs(catalog) {
    const response = await mainAxios.get(`/catalogo/${catalog}/subcatalogos`);
    return response.data;
  }
  async getSubCatalogsCompany(catalog, companyId) {
    const response = await mainAxios.get(
      `/catalogo/${catalog}/subcatalogos?empresa_id=${companyId}`
    );
    return response.data;
  }
  async createSubCatalog(payload) {
    const response = await mainAxios.post(`/subcatalogo-produto`, payload);
    return response.data;
  }
  async createProductSubCatalog(payload) {
    const response = await mainAxios.post(`/subcatalogos/produtos`, payload);
    return response.data;
  }
  async deleteSubCatalog(id) {
    const response = await mainAxios.delete(
      `/subcatalogo-produto/${id}?order_fields=idx_subcatalogo&order=asc`
    );
    return response.data;
  }
  async deleteSubCatalogProduct(id) {
    const response = await mainAxios.delete(
      `/subcatalogo-produto-index/${id}`
    );
    return response.data;
  }
  async restoreSubCatalogProduct(id) {
    const response = await mainAxios.put(
      `/subcatalogo-produto-index/${id}/restore`
    );
    return response.data;
  }
  async getProductBySubCatalogId(url) {
    const response = await mainAxios.get(url);
    return response.data;
  }
  async uploadImage(payload) {
    const response = await mainAxios.post(`/catalogo/upload-foto`, payload);
    return response.data;
  }
  async uploadSubCatalogImage(payload) {
    const response = await mainAxios.post(`/subcatalogo-produto/upload-foto`, payload);
    return response.data;
  }
  async deleteSubCatalogImage(id) {
    const response = await mainAxios.delete(`/subcatalogo-produto/excluir-foto/` + id);
    return response.data;
  }
  async saveCatalogOrder(payload) {
    const response = await mainAxios.put(`/catalogo/indexar`, payload);
    return response.data;
  }
  async saveSubCatalogOrder(payload) {
    const response = await mainAxios.put(`/subcatalogo/indexar`, payload);
    return response.data;
  }
  async updatedSubCatalog(id, payload) {
    const response = await mainAxios.put(`/subcatalogo-produto/${id}`, payload);
    return response.data;
  }
  async saveProductOrder(payload) {
    const response = await mainAxios.put(`/subcatalogos/produtos/indexar`, payload);
    return response.data;
  }
  async getCatalogCompanyId (companyId){
    const response = await mainAxios.get(`/catalogo?cod_emp=${companyId}&scope=true&paginate=false`);
    return response.data;
  }
}

export default new catalogService();
