const returnGenericColor = {
  methods: {
    createRandomColor(position) {
      const colors = [
        '#F38EB0',
        '#B19FDB',
        '#FFF49B',
        '#BDAAA4',
        '#84D2FF',
        '#A5D6A7',
        '#CE92D8',
        '#D3E5EF',
        '#FFE2DD',
        '#FFB8B9',
        '#FFB7CF',
        '#F5B9FF',
        '#D9C9FF',
        '#C1CBFC',
        '#B4DEFF',
        '#D3EFFF',
        '#A0F4FF',
        '#AEFFFA',
        '#D0FFD2',
        '#E3FFC9',
        '#FFFBDC',
        '#FFE8A1',
        '#FFDBA5',
        '#FFCBBB',
        '#E5CEC7',
        '#EEEEEE',
        '#CADCE5',
      ];
      return colors[position];
    },
  },
};
export default returnGenericColor;
