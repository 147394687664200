<template>
  <div class="sidemenu">
    <div class="logo">
      <img src="@/assets/img/frame.svg" width="36px" height="36px" alt="Descrição" />
    </div>
    <div class="menu-list">
      <ul class="nav flex-column">
        <li class="nav-item">
          <RouterLink
            class="nav-link" aria-current="page" data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-custom-class="custom-tooltip"
            to="home"
          >
            <img src="@/assets/img/home.svg" alt="" />
          </RouterLink>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            aria-current="page" href="javascript:"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            data-bs-custom-class="custom-tooltip"
            @click="toogleMenuAll($event)">
            <img src="../../assets/img/dashboard.svg" alt="" />
          </a>
          <ul
            class="submenu overflow-y-auto d-none"
            style="width: 260px"
          >
            <h1 class="mt-4 submenu-title w-75">Dashboard</h1>
            <li class="nav-item">
              <a class="toogle-menu" @click="toogleMenu($event)">
                Auditoria
              </a>
              <ul class="d-none">
                <li class="nav-item">
                  <div class="px-2 mx-1">
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_descontos')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/descontos'">Descontos</a>
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_estornos')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/estornos'">Estornos</a>
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_adquirencia')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/acquisition'">Transações adquirência</a>
                  </div>
                </li>
              </ul>
            </li>

            <li class="nav-item">
              <a class="toogle-menu" @click="toogleMenu($event)">
                CRM
              </a>
              <ul class="d-none">
                <li class="nav-item">
                  <div class="px-2 mx-1">
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_fidelidade')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/fidelity'">Fidelidade</a>
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_mailing')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/mailing'">Mailing</a>
                  </div>
                </li>
              </ul>
            </li>

            <li class="nav-item">
              <a class="toogle-menu" @click="toogleMenu($event)">
                Estoque
              </a>
              <ul class="d-none">
                <li class="nav-item">
                  <div class="px-2 mx-1">
                    <a :href="'/stock-position'">Posição de estoque</a>
                    <a :href="'/stock-kardex'">Kardex</a>
                  </div>
                </li>
              </ul>
            </li>

            <li class="nav-item">
              <a class="toogle-menu" @click="toogleMenu($event)">
                Fiscal
              </a>
              <ul class="d-none">
                <li class="nav-item">
                  <div class="px-2 mx-1">
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_fiscal_icms')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/livro-fiscal-icms'">Livro Fiscal - ICMS</a>
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_fiscal_iss')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/livro-fiscal-iss'">Livro Fiscal - ISS</a>
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_contingencia')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/contingency'">Notas em contingência</a>
                  </div>
                </li>
              </ul>
            </li>

            <li class="nav-item">
              <a class="toogle-menu" @click="toogleMenu($event)">
                Movimentação
              </a>
              <ul class="d-none">
                <li class="nav-item">
                  <div class="px-2 mx-1">
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_acompanhamento_loja')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/acompanhamento-casa'">Acompanhamento Lojas</a>
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_faturamento')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/faturamento'">Faturamento</a>
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_faturamento_dia')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/faturamento-por-dia'">Faturamento por dia</a>
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_faturamento_canal')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/faturamento-canal'">Faturamento por canal</a>
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_fechamento_caixa')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/fechamento-caixa'">Fechamento de caixa</a>
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_operacional')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/operacional'">Operacional</a>
                  </div>
                </li>
              </ul>
            </li>

            <li class="nav-item">
              <a class="toogle-menu" @click="toogleMenu($event)">
                Vendas
              </a>
              <ul class="d-none">
                <li class="nav-item">
                  <div class="px-2 mx-1">
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_vendas_analitico')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/sales-analytical'">Vendas analítico</a>
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_vendas_delivery')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/vendas-delivery'">Vendas delivery</a>
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_venda_produto')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/venda-produto'">Venda de produtos</a>
                  </div>
                </li>
                <li class="nav-item">
                  <div class="px-2 mx-1">
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_venda_produto')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/sales-products'">Vendas de produtos - Observações</a>
                  </div>
                </li>
                <li class="nav-item">
                  <div class="px-2 mx-1">
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_venda_servicos')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/venda-servico'">Venda de serviços</a>
                    <a
                      v-show="
                        user.role === 'SUPER_ADMIN' ||
                        (
                          !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards').length
                            ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_dashboards_vendas_funcionario')[0].gerenciar
                            : false
                        )
                      "
                      :href="'/vendas-funcionario'">Vendas por funcionário</a>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li class="nav-item" v-for="(item, index) in menuList" :key="index">
          <a class="nav-link" aria-current="page" href="javascript:" data-bs-toggle="tooltip"
             data-bs-placement="bottom" data-bs-custom-class="custom-tooltip"
             @click="toogleMenuAll($event)"><img
            src="@/assets/img/shopping-cart.svg" alt="" /></a>
          <ul class="submenu d-none"
              >
            <li>
              <h1 class="mt-4 submenu-title w-75">
                {{ item.title }}
              </h1>
            </li>
            <li v-for="(submenu, idx) in item.submenu.data" :key="idx">
              <RouterLink :to="submenu.href">{{ submenu.title }}</RouterLink>
            </li>
          </ul>
        </li>
        <!-- Gerenciar PDV -->
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="javascript:" data-bs-toggle="tooltip"
             data-bs-placement="bottom" data-bs-custom-class="custom-tooltip"
             @click="toogleMenuAll($event)"><img
            src="@/assets/img/resturant.svg" alt="" /></a>
          <ul class="submenu d-none"
              >
            <li>
              <h1 class="mt-4 submenu-title w-75">Gerenciar PDV</h1>
            </li>
            <li>
              <RouterLink :to="'/catalog'">Catálogo de Produtos</RouterLink>
              <RouterLink :to="'/sales'">Tela de Vendas</RouterLink>
            </li>
          </ul>
        </li>
        <!-- Compras e estoque -->
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="javascript:" data-bs-toggle="tooltip"
             data-bs-placement="bottom" data-bs-custom-class="custom-tooltip"
             @click="toogleMenuAll($event)"><img
            src="@/assets/img/box.svg" alt="" /></a>
          <ul class="submenu d-none"
              >
            <li>
              <h1 class="mt-4 submenu-title w-75">Compras e<br />Estoque</h1>
            </li>
            <li class="">
              <RouterLink to="/monitor-nfe">Monitor de nfe</RouterLink>
            </li>
            <li class="">
              <RouterLink to="/orders">Ordem de compra</RouterLink>
            </li>
            <li class="d-none">
              <RouterLink to="/stock-recipes">Receitas</RouterLink>
            </li>
            <li class="">
              <RouterLink to="/product-involved">Produto Envolvido</RouterLink>
            </li>
            <li class="">
              <RouterLink to="/receipt">Recebimento de mercadorias</RouterLink>
            </li>
          </ul>
        </li>
        <!-- Empresa -->
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="javascript:" data-bs-toggle="tooltip"
             data-bs-placement="bottom" data-bs-custom-class="custom-tooltip"
             @click="toogleMenuAll($event)"><img
            src="../../assets/img/company.svg" alt="" /></a>
          <ul class="submenu d-none"
              >
            <li>
              <h1 class="mt-4 submenu-title w-75">Empresas</h1>
            </li>
            <li>
              <RouterLink :to="'/employees'">Cadastro de funcionários</RouterLink>
            </li>
          </ul>
        </li>
        <!-- CRM -->
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="javascript:" data-bs-toggle="tooltip"
             data-bs-placement="bottom" data-bs-custom-class="custom-tooltip"
             @click="toogleMenuAll($event)">
            <img src="@/assets/img/crm_new.svg" alt="">
          </a>
          <ul class="submenu d-none"
              >
            <li>
              <h1 class="mt-4 submenu-title w-75">CRM</h1>
            </li>
            <li>
              <RouterLink to="/blocked-clients">Clientes bloqueados</RouterLink>
            </li>
            <li>
              <RouterLink to="/registered-customers">Clientes cadastrados</RouterLink>
            </li>
            <li>
              <RouterLink to="/manage-schedule">Gerenciar agenda</RouterLink>
            </li>
            <li>
              <RouterLink to="/manage-lists">Gerenciar listas</RouterLink>
            </li>
            <li>
              <RouterLink to="/exchange">Gerenciar permutas</RouterLink>
            </li>
          </ul>
        </li>
        <!-- Configurações -->
        <li class="nav-item">
          <a class="nav-link" aria-current="page" href="javascript:" data-bs-toggle="tooltip"
             data-bs-placement="bottom" data-bs-custom-class="custom-tooltip"
             @click="toogleMenuAll($event)"><img src="@/assets/img/settings.svg" alt="" /></a>
          <ul class="submenu d-none"
              >
            <li>
              <h1 class="mt-4 submenu-title w-75">Configurações</h1>
            </li>
            <li>
              <RouterLink :to="'/table-environment'">Ambientes e mesas</RouterLink>
            </li>
            <li>
              <RouterLink :to="'/entry-category'">Categoria de entrada</RouterLink>
            </li>
            <li>
              <RouterLink to="/category-icms">Categorias de ICMS e ISS</RouterLink>
            </li>
            <li>
              <RouterLink to="/category-pis-cofins">Categorias de PIS e Cofins</RouterLink>
            </li>
            <li>
              <RouterLink to="/equipment">Configurar Equipamentos</RouterLink>
            </li>
            <li>
              <RouterLink to="/stock">Configurar Estoque</RouterLink>
            </li>
            <li>
              <RouterLink to="/open-delivery">Configurar Open Delivery</RouterLink>
            </li>
            <li>
              <RouterLink to="/tax-rules">Configurar Regras Fiscais</RouterLink>
            </li>
            <li>
              <RouterLink to="/service">Configurar Serviços</RouterLink>
            </li>
            <li>
              <RouterLink :to="'/discounts-surcharges'">Descontos e Acréscimos</RouterLink>
            </li>
            <li>
              <RouterLink to="/direct-print">Direcionar Impressão</RouterLink>
            </li>
            <li>
              <RouterLink :to="'/payment-methods'">Formas de pagamentos</RouterLink>
            </li>
            <li>
              <RouterLink :to="'/reversal-reason'">Motivos de estorno</RouterLink>
            </li>
            <li>
              <div class="px-2 mx-1">
                <hr style="color: #FFFFFF!important;">
              </div>
            </li>
            <li v-show="
              user.role === 'SUPER_ADMIN' ||
              (
                !!logged.permissoes.filter((e) => e.name_modcadm === 'mod_config_avancadas').length
                  ? logged.permissoes.filter((e) => e.name_modcadm === 'mod_config_avancadas')[0].gerenciar
                  : false
              )
            ">
              <RouterLink :to="'/advanced-configurations'">Configurações avançadas</RouterLink>
            </li>
          </ul>
        </li>

        <li class="nav-item mt-4">
          <a href="javascript:" class="nav-link" @click="logout">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                 fill="none">
              <g clip-path="url(#clip0_6952_6859)">
                <path
                  d="M14 8V6C14 5.46957 13.7893 4.96086 13.4142 4.58579C13.0391 4.21071 12.5304 4 12 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21071 4.96086 3 5.46957 3 6V18C3 18.5304 3.21071 19.0391 3.58579 19.4142C3.96086 19.7893 4.46957 20 5 20H12C12.5304 20 13.0391 19.7893 13.4142 19.4142C13.7893 19.0391 14 18.5304 14 18V16"
                  stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M9 12H21L18 9" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                <path d="M18 15L21 12" stroke="white" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_6952_6859">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </li>
      </ul>
    </div>
    <div class="logo-epoc">
      <span><img src="@/assets/img/erp.svg" alt="" /></span>
    </div>

    <button type="button" class="btn btn-primary d-none" data-bs-toggle="modal"
            data-bs-target="#exampleModalUnder">
      Launch demo modal
    </button>

    <!-- Modal -->
    <div class="modal fade" id="exampleModalUnder" tabindex="-1" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered under-construction">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal"
                    aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <img src="@/assets/img/underconstruction.svg" alt="" />
            <h1>Opss, essa funcionalidade ainda não está disponível</h1>
            <p class="mb-0">
              Estamos em constante melhoria do nosso produto, em breve ela
              estará disponível
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList: [
        {
          title: 'Gestão de Produtos',
          icon: 'shopping-cart',
          submenu: {
            data: [
              {
                title: 'Painel de Produtos',
                href: '/products'
              },
              {
                title: 'Tabela de Preços',
                href: '/price-table'
              },
              {
                title: 'Observações de Produtos',
                href: '/product-observation'
              },
              {
                title: 'Grupo de Produtos',
                href: '/product-group'
              },
              {
                title: 'Tabela de fracionamento',
                href: '/fraction-table'
              },
              {
                title: 'Tags',
                href: '/tags'
              },
            ]
          }
        }
      ],
      logged: {
        permissoes: []
      },
      user: {
        role: 'USER'
      }
    };
  },
  mounted() {
    document.querySelectorAll('ul.submenu').forEach((el) => {
      el.addEventListener('mouseleave', () => el.classList.add('d-none'))
    })

    this.logged = JSON.parse(sessionStorage.getItem('logged'));
    this.user = JSON.parse(sessionStorage.getItem('userData'));

    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );
    const tooltipList = [...tooltipTriggerList].map(
      (tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl)
    );
  },
  methods: {
    toogleMenu(event) {
      event.target.classList.toggle('active');
      let el = event.target.parentElement.getElementsByTagName('ul');
      el[0].classList.toggle('d-none');
    },
    toogleMenuAll(event) {
      document.querySelectorAll('ul.submenu').forEach((el) => {
        el.classList.add('d-none');
      })

      if (event.target.classList.contains('nav-link')) {
        let elm = event.target.parentElement;
        elm.classList.toggle('active');
        elm.children[1].classList.toggle('d-none');
        return;
      }

      let el = event.target.parentElement.parentElement;
      el.classList.toggle('active');
      el.children[1].classList.toggle('d-none');
    },
    hiddenMenu(event) {
      event.target.parentElement.classList.toggle('active');
      event.target.classList.toggle('d-none');
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/auth');
    }
  }
};
</script>

<style lang="scss">

a.toogle-menu {
  position: relative;
  cursor: pointer;

  &::after {
    content: '\F282';
    font-family: 'bootstrap-icons';
    background-image: unset;
    float: right;
    position: absolute;
    right: 10px;
  }

  &:not(.active)::after {
    content: '\F285';
    font-family: 'bootstrap-icons';
    transform: unset;
  }
}

.sidemenu {
  background-color: #181a1b;
  height: 100vh;
  width: 60px;
  display: grid;
  z-index: 9999;
  position: fixed;

  .menu-list {
    .nav {
      width: 100%;
      gap: 25px;
      align-items: center;
    }
  }
}

.logo {
  width: 45px;
  height: 70px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-bottom: 1px solid #4b5053;

  img {
    margin-top: 20px;
  }
}

.logo-epoc {
  display: flex;
  justify-content: center;
}

.submenu {
  position: absolute;
  margin-left: 52px;
  background-color: #212425;
  top: 0px;
  height: 100%;
  width: 230px;
  z-index: 1;
  padding: 0;

  .submenu-title {
    font-weight: 700;
    font-size: 20px;
    color: #ffffff;
    margin-bottom: 24px;
    padding-left: 16px;
    font-family: $font-secondary;
  }

  li {
    a {
      height: 50px;
      line-height: 50px;
      padding-left: 16px;
      text-decoration: none;
      font-weight: 400;
      font-size: 12px;
      color: #ffffff;
      font-family: $font-secondary;
      display: block;

      &:hover {
        background: #2d3032;
      }
    }
  }
}

.custom-tooltip {
  --bs-tooltip-bg: #2f2f2f;
  --bs-tooltip-opacity: 1;
  --bs-tooltip-padding-x: 8px;
  --bs-tooltip-padding-y: 8px;
  --bs-tooltip-border-radius: 4px;
  @include rfs(14px, --bs-tooltip-font-size);
}
</style>
