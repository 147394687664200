<template>
  <div class="tag-input-container">
    <div class="form-control d-flex flex-wrap align-items-center p-2">
      <div
        v-for="(tag, index) in selectedTags"
        :key="index"
        class="badge me-1 mb-1"
        :style="{ backgroundColor: tag.cor , cursor: 'pointer', color: '#872240'}"
      >
        {{ tag.tag }}
        <span class="ms-1" @click.stop="removeTag(index)">&times;</span>
      </div>
      <input
        ref="input"
        type="text"
        v-model="inputValue"
        @input="onInput"
        @keydown.delete="handleDelete"
        @keydown.enter.prevent="addCurrentTag"
        @keydown.down.prevent="navigateDown"
        @keydown.up.prevent="navigateUp"
        @keydown.enter="handleComma"
        @keydown="debounceSearch"
        @blur="onBlur"
        @focus="onFocus"
        placeholder="Digite para adicionar tags..."
        class="border-0 flex-grow-1 p-0 bg-transparent py-2"
      />
    </div>
    <div v-if="showSuggestions && filteredTags.length > 0" class="mt-1 d-flex flex-wrap">
      <div
        v-for="(tag, index) in filteredTags"
        :key="tag.tag"
        @click="selectTag(tag)"
        :class="['badge me-1 mb-1 p-2', index === activeIndex ? 'border border-dark' : '']"
        :style="{ backgroundColor: tag.cor, cursor: 'pointer' }"
      >
        {{ tag.tag }}
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from '@/plugins/debounce';

export default {
  name: 'EpTagInput',
  props: {
    call: {
      type: Function,
      default: () => {}
    },
    availableTags: {
      type: Array,
      default: () => []
    },
    value: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      debounceSearch: () => {},
      inputValue: '',
      selectedTags: this.value,
      showSuggestions: false,
      activeIndex: -1
    }
  },
  computed: {
    filteredTags() {
      const input = this.inputValue.toLowerCase().trim()
      if (!input) return this.availableTags.filter(tag =>
        !this.selectedTags.some(selectedTag => selectedTag.tag === tag.tag)
      )

      return this.availableTags.filter(tag =>
        tag.tag.toLowerCase().includes(input) &&
        !this.selectedTags.some(selectedTag => selectedTag.tag === tag.tag)
      )
    }
  },
  mounted() {
    this.debounceSearch = debounce(() => {
      this.call(this.inputValue)
    }, 600)
  },
  watch: {
    value(newVal) {
      this.selectedTags = newVal
    },
    selectedTags(newVal) {
      this.$emit('changeTags', newVal)
    }
  },
  methods: {
    onInput() {
      this.showSuggestions = true
      this.activeIndex = -1
    },
    onFocus() {
      this.showSuggestions = true
    },
    onBlur() {
      setTimeout(() => {
        this.showSuggestions = false
        this.addCurrentTag()
      }, 200)
    },
    selectTag(tag) {
      if (!this.selectedTags.some(selectedTag => selectedTag.tag === tag.tag)) {
        this.selectedTags.push(tag)
        this.inputValue = ''
        this.showSuggestions = false
        this.activeIndex = -1
      }
    },
    addCurrentTag() {
      const value = this.inputValue.trim()
      if (value) {
        // Verificar se a tag já existe nas disponíveis
        const existingTag = this.availableTags.find(tag =>
          tag.tag.toLowerCase() === value.toLowerCase()
        )

        if (existingTag && !this.selectedTags.some(tag => tag.tag === existingTag.tag)) {
          // Se existir nas disponíveis, use-a
          this.selectedTags.push(existingTag)
        } else if (!this.selectedTags.some(tag => tag.tag.toLowerCase() === value.toLowerCase())) {
          // Se não existir, crie uma nova com cor padrão
          this.selectedTags.push({
            tag: value,
            cor: 'rgb(200, 200, 200)' // Cor padrão para novas tags
          })
        }
        this.inputValue = ''
      } else if (this.activeIndex >= 0 && this.filteredTags[this.activeIndex]) {
        this.selectTag(this.filteredTags[this.activeIndex])
      }
    },
    removeTag(index) {
      this.selectedTags.splice(index, 1)
    },
    handleDelete(e) {
      if (this.inputValue === '' && this.selectedTags.length > 0) {
        this.selectedTags.pop()
      }
    },
    handleComma(e) {
      e.preventDefault()
      this.addCurrentTag()
    },
    navigateDown() {
      if (this.filteredTags.length > 0) {
        this.activeIndex = (this.activeIndex + 1) % this.filteredTags.length
      }
    },
    navigateUp() {
      if (this.filteredTags.length > 0) {
        this.activeIndex = this.activeIndex <= 0 ? this.filteredTags.length - 1 : this.activeIndex - 1
      }
    }
  }
}
</script>

<style scoped>
.tag-input-container {
  position: relative;
  width: 100%;
  .form-control {
    height: auto!important;
  }
}

input:focus {
  outline: none;
}

.badge {
  color: #000; /* Texto escuro para melhor contraste com cores claras */
}
</style>
