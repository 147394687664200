<template>
  <div class="tag mb-1" :style="styleObject">
    <input
      :style="styleObject"
      v-model="model"
      type="radio"
      class="btn-check"
      :id="id"
      :name="nameRadion"
      :value="valueRadion"
      autocomplete="off"
    />
    <label :style="styleObject" class="btn btn-tag col-4" :for="id">{{ label }}</label>
  </div>
</template>

<script>
export default {
  name: 'EpCheckedRadio',
  props: ['label', 'id', 'valueRadion', 'nameRadion', 'color', 'selecting', 'backgoundcolor'],
  computed: {
    model: {
      get() {
        return this.selecting;
      },
      set(value) {
        console.log(value)
        this.$emit('update:selecting', value);
      }
    },
    styleObject() {
      return {
        '--button-background-color': this.backgoundcolor || '#872240',
        '--button-color': this.color || 'white',
      };
    },
  }
}
</script>

<style lang="scss" scoped>
.tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  .btn-tag {
    display: unset;
    background: #ffffff;
    border: 1px solid #c0c5c8;
    border-radius: 56px;
    padding: 8px 16px;

    line-height: 1.5;
    font-family: $font-secondary;
    font-weight: 700;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    color: var(--button-color);

    &:hover {
      background-color: var(--button-background-color);
      border: 1px solid var(--button-background-color);
      color: var(--button-color);
    }
  }

  .btn-check:checked + .btn{
    background-color: var(--button-background-color);
    border: none;
    color: var(--button-color);
  }

}



</style>
