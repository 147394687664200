<template>
  <div style="cursor: pointer; width: 100%!important; height: 100%!important;" @click="Clipboard($event)">
    <em class="bi bi-copy"></em>
  </div>
</template>

<script>
export default {
  name: 'EpCopy',
  props: ['value', 'message'],
  methods: {
    Clipboard(event) {
      navigator.clipboard.writeText(this.value)
        .then(() => {
          let el = event.target;

          if (event.target.tagName === 'EM') {
            el = event.target.parentElement;
          }

          el.innerHTML = '<em class="bi bi-check"></em>';
          setTimeout(() => {
            el.innerHTML = '<em class="bi bi-copy"></em>';
          }, 1000);

          Toast.fire({
            title: 'Sucesso!',
            html: this.message,
            icon: 'success',
            width: 600,
            showCloseButton: true,
            timerProgressBar: false
          });
        });
    },
  }
};
</script>
<style scoped lang="scss">

</style>
