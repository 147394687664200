import { mainAxios } from '@/plugins/axios';

class importProductsService {
  async upload(data, config) {
    return await mainAxios.post(`importacao-produto/importar`, data, config);
  }

  async list(pageUrl, perPage, filters) {
    const response = await mainAxios.get(
      `${pageUrl}&per_page=${perPage}&${filters}`
    );
    return response.data;
  }

  async latest() {
    let response = await mainAxios.get(`/importacao-produto`);
    return response.data;
  }

  async delete(id) {
    return await mainAxios.delete(`/importacao-produto/${id}`);
  }

  async update(id, payload = {}) {
    const response = await mainAxios.put(
      `/importacao-produto-item/${id}`,
      payload
    );
    return response.data;
  }

  async create(id) {
    const response = await mainAxios.post(`/importacao-produto/${id}`);
    return response.data;
  }

  async status(id) {
    const response = await mainAxios.get(`/importacao-status/${id}`);
    return response.data;
  }

  async getTablePrice(id) {
    const response = await mainAxios.get(`/importacao-produto/${id}/tabela-preco`);
    return response.data;
  }
}

export default new importProductsService();
