import { mainAxios } from '@/plugins/axios';

class opendeliveryService {
  async list(url) {
    const response = await mainAxios.get(url);
    return response.data;
  }
  async listAll(search = '', limit = '') {
    const response = await mainAxios.get(`/open-delivery?search=${search}&paginate=false&limit=${limit}`);
    return response.data;
  }
  async delete(id) {
    const response = await mainAxios.delete(`/open-delivery/${id}`);
    return response.data;
  }
  async create(payload) {
    const response = await mainAxios.post(`/open-delivery`, payload);
    return response.data;
  }
  async update(id, payload) {
    const response = await mainAxios.put(`/open-delivery/${id}`, payload);
    return response.data;
  }
  async view(id) {
    const response = await mainAxios.get(`/open-delivery/${id}`);
    return response.data;
  }
  async config(form) {
    const response = await mainAxios.post(`/open-delivery-config`, form);
    return response.data;
  }
}

export default new opendeliveryService();
