<template>
  <div class="square-picker position-fixed" style="z-index: 99999; background: #fff;">
    <div :class="{'tags-picker':true, 'd-none': !tooglePalletColors}" :style="{top: '50px', left: '0'}">
      <div class="tag-square mb-2" v-for="(label, i) in colors" :key="i">
        <input
          v-model="model"
          type="radio"
          class="btn-check"
          :id="`ColorPicker_${i}`"
          name="ColorPicker"
          :value="label"
          autocomplete="off"
        />
        <label class="btn btn-tag-square" :style="styleObject(i)" :for="`ColorPicker_${i}`">
          <span class="square" :style="{ background: label }"></span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EpColorPicker',
  props: ['checked', 'tooglePalletColors'],
  data() {
    return {
      colors: [
        "#F38EB0", "#FFB7CF", "#FFB8B9", "#FFCBBB", "#FFE2DD",
        "#BDAAA4", "#E5CEC7", "#EEEEEE",
        "#FFFBDC", "#FFF49B", "#FFE8A1", "#FFDBA5",
        "#B1E2B3", "#D9F2D3", "#E8FFC9",
        "#CE92D8", "#B19FDB", "#F5B9FF", "#D9C9FF",
        "#C1CBFC", "#84D2FF", "#B4DEFF", "#A0F4FF", "#AEFFFA", "#D3EFFF", "#CADCE5", "#D3E5EF"
      ],
    }
  },
  computed: {
    model: {
      get() {
        return this.checked;
      },
      set(value) {
        this.$emit('update:checked', value);
      }
    }
  },
  methods: {
    styleObject(i) {
      return {
        '--button-background-color': this.colors[i],
      };
    },
  }
}
</script>

<style lang="scss" scoped>
.square {
  width: 58px;
  height: 58px;
  margin-right: 8px;
}

.tags-picker {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;

  .btn-tag-square {
    display: table;
    background: var(--button-background-color);
    border: 1px solid var(--button-background-color);
    border-radius: 5px;
    padding: 16px 16px;
    line-height: 1.5;
    font-family: $font-secondary;
    font-weight: 700;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    color: var(--button-background-color);
    width: 58px;
    height: 58px;
    &:hover {
      background-color: var(--button-background-color);
      border: 1px solid var(--button-background-color);
    }
  }

  .btn-check:checked + .btn{
    background-color: var(--button-background-color);
    border: none;
  }

}



</style>
