import { mainAxios } from '@/plugins/axios';

class monitorNfeService {
  async list(query) {
    const response = await mainAxios.get(query);
    return response.data;
  }
  async create(data) {
    const response = await mainAxios.post('/categoria-icms', data);
    return response.data;
  }
  async update(id, data) {
    const response = await mainAxios.put('/categoria-icms/' + id, data);
    return response.data;
  }
  async view(id) {
    const response = await mainAxios.get('/categoria-icms/' + id);
    return response.data;
  }
  async delete(id) {
    const response = await mainAxios.delete('/categoria-icms/' + id);
    return response.data;
  }
  async download(payload) {
    const response = await mainAxios.post('/nfe-distribuida/download', payload);
    return response.data;
  }
  async downloadXml(id) {
    const response = await mainAxios.get('/nfe-distribuida/download-xml/' + id, { responseType: 'blob' });
    return response.data;
  }
  async getNfeChave(codEmp, chave) {
    const response = await mainAxios.get(`/nfe-distribuida/${codEmp}/${chave}`);
    return response.data;
  }
}

export default new monitorNfeService();
