<template>
  <div class="tag-input-wrapper">
    <!-- Container principal do input com a cor de fundo selecionada -->
    <div
      class="tag-input-container"
      :style="{ backgroundColor: model.color }"
    >
      <input
        :style="CustomStyle"
        ref="input"
        type="text"
        :value="model.text"
        @input="bindInput($event.target.value)"
        @focus="focus"
        :class="['tag-input', CustomClass]"
        :placeholder="placeholder || 'Digite aqui...'"
      />

      <!-- Indicador de seleção de cor -->
      <div
        title="Selecionar a cor"
        class="color-indicator"
        :style="{ backgroundColor: model.color }"
        @click="showColorPicker = !showColorPicker"
      >
        <span class="bi bi-app-indicator"></span>
      </div>
    </div>

    <!-- Seletor de cores (visível apenas quando showColorPicker é true) -->
    <div v-if="showColorPicker" class="color-picker">
      <div class="color-picker-header">
        <span>Selecione uma cor</span>
        <button class="close-button" @click="showColorPicker = false">×</button>
      </div>
      <div class="color-options">
        <div
          v-for="(color, index) in colors"
          :key="index"
          class="color-option"
          :class="{ 'selected': model.color === color }"
          :style="{ backgroundColor: color }"
          @click="selectColor(color)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EpInputSelectColor',
  props: ['item', 'placeholder', 'CustomClass', 'CustomStyle'],
  data() {
    return {
      showColorPicker: false,
      colors: [
        "#F38EB0", "#FFB7CF", "#FFB8B9", "#FFCBBB", "#FFE2DD",
        "#BDAAA4", "#E5CEC7", "#EEEEEE",
        "#FFFBDC", "#FFF49B", "#FFE8A1", "#FFDBA5",
        "#B1E2B3", "#D9F2D3", "#E8FFC9",
        "#CE92D8", "#B19FDB", "#F5B9FF", "#D9C9FF",
        "#C1CBFC", "#84D2FF", "#B4DEFF", "#A0F4FF", "#AEFFFA", "#D3EFFF", "#CADCE5", "#D3E5EF"
      ]
    }
  },
  computed: {
    model: {
      get() {
        return {
          text: this.item.text,
          color: this.item.color
        };
      },
      set(value) {
        this.$emit('update:changeValues', value);
      }
    }
  },
  methods: {
    selectColor(val) {
      this.model.color = val;
      this.$emit('update:changeValues', this.model);
    },
    bindInput(val) {
      this.model.text = val;
      this.$emit('update:changeValues', this.model);
    },
    focus() {
      this.$refs.input.focus();
    },
    closeColorPicker(event) {
      if (!this.$el.contains(event.target)) {
        this.showColorPicker = false;
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.closeColorPicker);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeColorPicker);
  }
}
</script>

<style lang="scss" scoped>
.tag-input-wrapper {
  position: relative;
  display: inline-block;
}

.tag-input-container {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 10px;
  transition: all 0.2s ease;
}

.tag-input {
  background: transparent;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 450;
  min-width: 100px;
  color: var(--bs-secondary-color);
}

.tag-input::placeholder {
  color: var(--bs-secondary-color);
}

.color-indicator {
  display: flex;
  width: 20px;
  cursor: pointer;
}

.color-icon {
  font-size: 14px;
  margin: 0 auto;
  color: var(--bs-secondary-color);
}

.color-picker {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 280px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px var(--bs-secondary-color);
  z-index: 100;
}

.color-picker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  border-bottom: 1px solid #eee;
}

.close-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: var(--bs-secondary-color);
}

.color-options {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  gap: 6px;
  max-height: 200px;
  overflow-y: auto;
}

.color-option {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid transparent;
  transition: transform 0.2s, border-color 0.2s;
}

.color-option:hover {
  transform: scale(1.1);
}

.color-option.selected {
  border-color: #333;
}
</style>
